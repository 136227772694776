<header [ngClass]="currentRole === internalRole ? 'nadel-light-header-bg' : 'vendor-light-header-bg'">
  <div class="custom-container">
    <div class="col-lg-12 d-flex justify-content-between align-items-center">
      <div class="logo col-lg d-flex gap-4 align-items-center">
        <a [routerLink]="homeRouterLink">
          <img src="/assets/images/logo.svg" alt="Logo">

        </a>

        <span class="complogo-name">
          @if(loggedInUserRole !== userRole.Internal){
          {{(companyName.length > 60)? ( companyName | slice:0:60)+". . ." : companyName}}
          }
        </span>

        @if(loggedInUserRole === userRole.Internal) {
        <!-- <div class="badge-grid">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button routerLink="/dashboard/signup" label="Invite Vendor" [rounded]="false"
                          severity="secondary"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Vendor Scoreboard" [rounded]="false" severity="secondary"></p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button routerLink="/vendor/specials-management" label="Specials" [rounded]="false"
                          severity="secondary"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Internal Users" [rounded]="false" severity="secondary"
                          routerLink="/internal/user-management-listing"></p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button routerLink="/internal/all-vendors" label="D365 Vendors" [rounded]="false" severity="secondary"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Events" [rounded]="false" severity="secondary"
                          routerLink="/vendor/event-management"></p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Rebates" [rounded]="false" severity="secondary"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Invoices" [rounded]="false" severity="secondary"
                          routerLink="/vendor/vendor-invoices"></p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Document Templates" [rounded]="false" severity="secondary"
                          routerLink="/document/templates"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Vendor Status" [rounded]="false" severity="secondary"
                          routerLink="/internal/vendor-status"></p-button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Configuration" routerLink="/configuration/configuration" [rounded]="false"
                          severity="secondary"></p-button>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                <p-button label="Reviews" [rounded]="false" severity="secondary" routerLink="/vendor/reviews">
                </p-button>
              </div>
            </div>
          </div> -->





        <div class="nade-navigation">
          <ul class="d-flex gap-3">
            <li>
              <div class="dropdown-container">
                <a href="#" (click)="toggleDropdown($event)">Vendor <i class="pi pi-angle-down"></i></a>
                <div class="dropdown-menu rounded-bottom" *ngIf="isDropdownOpen">
                  <a routerLink="/dashboard/signup">Invite Vendor</a>
                  <!-- <a href="#">Vendor Scoreboard</a> -->
                  <a routerLink="/internal/all-vendors">D365 Vendors</a>
                  <a routerLink="/vendor/event-management">Events</a>
                  <a routerLink="/internal/vendor-status">Vendor Status</a>
                  <a routerLink="/vendor/reviews" class="border-0 rounded-bottom">Reviews</a>
                </div>
              </div>

            </li>
            <li><a routerLink="/vendor/vendor-invoices" routerLinkActive="active-link">Invoices</a></li>
            <li><a routerLink="/vendor/specials-management" routerLinkActive="active-link">Specials</a></li>
          </ul>


        </div>


        }

      </div>

      <div class="col-lg d-flex d-md-block">
        <div class="col-auto d-flex justify-content-end gap-4">
          <div class="col-auto">
            <ul class="nav tab-bar flex-nowrap overflow-auto-md text-nowrap active">
              @if(loggedInUserRole !== userRole.Internal) {
              <li class="nav-item">
                <a class="p-4 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                  [routerLink]="dashboardLink" routerLinkActive="active-link">Dashboard</a>
              </li>
              <li class="nav-item">
                <a class="p-4 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                  [routerLink]="informationRouterLink" routerLinkActive="active-link"
                  [queryParams]="informationQueryParams">
                  Vendor
                  Application
                </a>
              </li>
              <!-- <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="/vendor/vendor-invoices" routerLinkActive="active-link">Invoices</a>
              </li>
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="/vendor/reporting" routerLinkActive="active-link">Reporting</a>
              </li> -->

              <li class="nav-item">
                <a class="p-4 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                  routerLink="/vendor/event-management" routerLinkActive="active-link">Events</a>
              </li>
              <li class="nav-item">
                <a class="p-4 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                  routerLink="/vendor/vendor-invoices" routerLinkActive="active-link">Invoices</a>
              </li>
              <li class="nav-item">
                <a class="p-4 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                  routerLink="/vendor/specials-management" routerLinkActive="active-link">Specials</a>
              </li>
              }
            </ul>


          </div>
          <div class="col-auto d-flex align-items-center gap-3">
            @if(loggedInUserRole !== userRole.Internal) {
            <div class="switch-arrow">

              <p-button (onClick)="op.toggle($event)" icon="pi pi-arrow-right-arrow-left" />
              <p-overlayPanel #op>
                <div class="flex flex-column gap-3 w-25rem">
                  <div class="company-listing">
                    <ul class="list-unstyled">
                      <li (click)="triggerVendorSelection(vendor.accountNumber)" *ngFor="let vendor of multipleVendors">
                        {{ vendor.accountName }}</li>

                    </ul>
                  </div>
                </div>
              </p-overlayPanel>

            </div>
          }
            <!--<span routerLink="/discussions" class="header-notification discusison-chat">
              <img src="/assets/images/discussion-chat-icon.png" alt="chat">
              <span class="discussion-count">{{ notifications }}</span>
            </span>-->
            <span routerLink="/discussions" class="header-notification discusison-chat">
              <img src="/assets/images/discussion-chat-icon.png" alt="chat">
              <span class="discussion-count">{{ notifications }}</span>
            </span>
          </div>
          <div class="col-auto profile-box d-flex align-items-center">

            <div class="col">
              <!-- <div class="justify-content-center d-flex align-items-center gap-4 mt-1">
              <span class="header-notification">
                <img src="assets/images/search-icon.png" alt="profile icon">
              </span>
              <span class="header-notification">
                <img src="assets/images/calender-icon.png" alt="profile icon">
              </span>
               </div>
              -->
              <!-- <span routerLink="/discussions" class="header-notification discusison-chat">
                <img src="/assets/images/discussion-chat-icon.png" alt="chat">
                <span class="discussion-count">{{ notifications }}</span>
              </span>
              -->



              <div class="profile-box">
                <span class="white-icon">
                  <img src="assets/images/profile-icon.jpg" alt="profile icon">
                </span>

                <p-splitButton [label]=currentUser [model]="this.items"
                  styleClass="p-button-text p-button-plain  mb-0 position-relative "></p-splitButton>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div>

      </div>
      <!-- <div class="col-lg-5">
          <div class="float-end">
              <p-toggleButton [(ngModel)]="checked" offLabel="" onIcon="pi pi-sun" offIcon="pi pi-moon"
                  (onChange)="switchTheme()" class="t-mode"></p-toggleButton>
          </div>
      </div> -->

    </div>
  </div>
</header>