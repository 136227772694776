export const environment = {
    production: true,
    vapidKey: "BOEO4zEYQyNGGIzFnE2iuOX0HVZGuFT77F_CcQvD_z6GQsw3GIN5sLTBiIdFwn1yvqeBGUl8eSgxsxomfeuyCuk",
    apiBaseUrl: "https://api-jnivendorportal-prod-f3fnesgfgrfbeydz.westus-01.azurewebsites.net",
    identityAuthority: "https://knowhere-uat.nadel.com",
    clientId: "dcea3166-979d-4d4c-bfe6-6517fb941449",
    msal: {
        clientId: "3ac19d67-b33c-475b-bcf4-ea378dc274c2",
        authority:
          "https://nadelsupplierportal.b2clogin.com/nadelsupplierportal.onmicrosoft.com/B2C_1_SignInSignUp",
        redirectUri: "https://supplierportal.nadel.com/",
        postLogoutRedirectUri: "https://supplierportal.nadel.com/",
        graphUrl: "https://graph.microsoft.com/v1.0",
        scope: "",
        knownAuthorities: "nadelsupplierportal.b2clogin.com",
        apiResource: "https://nadelsupplierportal.onmicrosoft.com/vendorportalcoreprod/api/Api.Access",
        // eslint-disable-next-line max-len
        passwordResetFlow: "https://nadelsupplierportal.b2clogin.com/nadelsupplierportal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=3ac19d67-b33c-475b-bcf4-ea378dc274c2&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fsupplierportal.nadel.com%2F&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=zvY-Iy5ijC5Gs4fdto9Lu1wMYKoo8Oc5IExRziSIBnk"
    },
};
