export enum EmailTemplateType {
    NewVendorInvitation = "1",
    ExistingVendorInvitation = "2",
    SubVendorInvitation = "3",
    MFACode = "4",
    WorkFlow = "5",
    RegistrationCompleteToVendor = "6",
    WorkFlowReviewToNadel = "7",
    EventByVendorCreatedOrUpdatedToInternalUser = "8",
    VendorActionOnEventToNadel = "9",
    EventByInternalUserCreatedOrUpdatedToVendor = "10",
    InternalUserActionOnEventToVendor = "11",
    RegistrationCompleteToNadel = "12",
    WorkflowReviewToVendor = "13",
    SpecialByVendorCreatedOrUpdatedToNadel = "14",
    SpecialApprovedByInternalUserToVendor = "15",
    EventCancelledUser = "16",
    DiscussionTopic = "17",
    DiscussionTopicChat = "18",
    DocumentUpdate = "19",
    Feedback = "20"
}
