import {
    Component, EventEmitter, Input, OnInit, Output
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, ReplaySubject } from "rxjs";

import { EmailAttachment } from "../../dashboard/components/vendor-signup/models/email-attachment.model";
import { EmailTemplateEnum } from "../../dashboard/components/vendor-signup/models/Enums/emailTemplateEnum.enum";
import { EmailModalDataModel } from "./models/email-modal-data.model";

@Component({
    selector: "app-email-modal",
    templateUrl: "./email-modal.component.html",
    styleUrls: ["./email-modal.component.scss"]
})
export class EmailModalComponent implements OnInit {
    @Input() showEmailTemplate: boolean = false;
    @Input() modalData!: EmailModalDataModel;
    @Input() isInviteLog: boolean = false;

    @Input() emailTemplateForm!: FormGroup;
    @Input() vendorForm!: FormGroup;

    @Output() eventUserSaved = new EventEmitter<void>();
    @Output() eventModalClose = new EventEmitter<void>();
    @Output() eventSendInvite = new EventEmitter<void>();

    vendorUsersInProgress = false;
    EmailTemplateEnum = EmailTemplateEnum;
    listOfAttachments: EmailAttachment[] = [];
    fileBase64Index = 0;

    constructor(
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
    }

    sendEmailInvite() {
        this.eventSendInvite.emit();
    }

    closeModal() {
        this.eventModalClose.emit();
    }

    onRemoveFile(event: any) {
        let indexOfFile = -1;
        this.listOfAttachments.forEach((item: EmailAttachment, index) => {
            if (event.file.name === item.FileName) {
                indexOfFile = index;
            }
        });
        if (indexOfFile > -1) {
            this.listOfAttachments.splice(indexOfFile, 1);
            this.emailTemplateForm.patchValue({ [EmailTemplateEnum.Attachments]: this.listOfAttachments });
        }
    }

    onFileSelected(event: any) {
        this.fileBase64Index = 0;
        const fileList = event.files;
        if (!fileList) {
            return;
        }
        for (let i = 0; i < fileList.length; i += 1) {
            this.convertFile(fileList[i]).subscribe((base64) => {
                this.listOfAttachments[this.fileBase64Index] = {
                    ...this.listOfAttachments[this.fileBase64Index],
                    Base64: base64
                };
                this.fileBase64Index += 1;
                this.emailTemplateForm.patchValue({ [EmailTemplateEnum.Attachments]: this.listOfAttachments });
            });
        }
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));
        this.listOfAttachments.push({ FileName: file.name });
        return result;
    }
}
