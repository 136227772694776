import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { StorageService } from "../../../core/helper/storage.service";
import { UserRole } from "../../../core/models/Enums/userRole.enum";

@Component({
    selector: "app-tab",
    templateUrl: "./tab.component.html",
    standalone: false,
    styleUrl: "./tab.component.scss"
})
export class TabComponent implements OnInit {
    accountNumber = "";
    summaryRouterLink = "";
    dashboardLink = "";
    informationRouterLink = "";
    summaryQueryParams = {};
    informationQueryParams = {};
    UserRole = UserRole;
    LoggedInUserRole = "";
    routerObject;
    constructor(
        private storageService: StorageService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.routerObject = router;
    }

    ngOnInit() {
        const userDetails: string | null = this.storageService.get("userDetails");
        const { userRole } = JSON.parse(userDetails ?? "");
        this.LoggedInUserRole = userRole;
        this.summaryRouterLink = "/vendor/summary";
        this.informationRouterLink = "/vendor/vendor-information";
        this.dashboardLink = "vendor/dashboard";
        if (userRole === UserRole.Internal) {
            this.route.queryParamMap.subscribe((param: any) => {
                const { accountNumber } = param.params;
                this.accountNumber = accountNumber;
                this.summaryQueryParams = { accountNumber };
                this.informationQueryParams = { accountNumber };
            });
            this.dashboardLink = "dashboard";
        } else {
            const accountNumber = JSON.parse(userDetails ?? "").vendorAccountNumber;
            this.accountNumber = accountNumber;
        }
    }
}
