<div class="m-noti border bg-white rounded">
   
        <div class="d-flex justify-content-between p-3 border-bottom align-items-center">
            <h4 class="mb-0" >Notifications
                <span class="notification-icon"><i class="pi pi-bell text-3xl" pBadge
                        [value]="notificationCount"></i></span>
            </h4>

            <span class="view-all" routerLink="/notification/list" > View All </span>
        </div>
        <div class="col-lg-12 noti-min-hgt p-3">
            <!-- <app-badge-notifications class="m-noti-bar d-none" [notification]="notification"
                [notificationDescription]="notificationDescription" [notificationType]="notificationType"
                [notificationTime]="notificationTime"></app-badge-notifications>
            <app-badge-notifications class="noti-des d-none" [notification]="notification2"
                [notificationDescription]="notificationDescription2" [notificationType]="notificationType2"
                [notificationTime]="notificationTime2"></app-badge-notifications> -->
            @for(notification of notifications; track notification.id) {
            <app-badge-notifications class="noti-des" (click)="onClickNotification(notification.reference, notification.id, 
            notification.notificationContextId, 
                notification.isActionable, notification.isProcessed)"
                [notificationDescription]="notification.description" [notificationTime]="notification.createdDate"
                [isProcessed]="notification.isProcessed">
            </app-badge-notifications>
            }
        </div>
    
</div>