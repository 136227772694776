import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { DiscussionModel } from "../../modules/discussion-board/components/create-discussion-topic/models/discussion.model";
import { CommentModel } from "../../modules/discussion-board/components/discussion-chat-screen/models/comment.model";
import { GenericResponseDto } from "../models/genericResponseDto.model";

@Injectable({
    providedIn: "root"
})
export class DiscussionsService {
    apiBaseUrl = `${environment.apiBaseUrl}/Discussion/`;

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    constructor(private httpClient: HttpClient) { }

    private notificationCountSubject = new BehaviorSubject<number>(0);
    notificationCount$ = this.notificationCountSubject.asObservable();

    updateNotificationCount(count: number) {
        this.notificationCountSubject.next(count);
    }

    addDiscussion(data: DiscussionModel): Observable<GenericResponseDto<DiscussionModel>> {
        return this.httpClient.post<GenericResponseDto<DiscussionModel>>(
            `${this.apiBaseUrl}CreateDiscussion`,
            data,
            this.httpOptions
        );
    }

    updateDiscussion(data: DiscussionModel): Observable<GenericResponseDto<DiscussionModel>> {
        return this.httpClient.put<GenericResponseDto<DiscussionModel>>(
            `${this.apiBaseUrl}`,
            data,
            this.httpOptions
        );
    }

    getDiscussions(data: any): Observable<GenericResponseDto<DiscussionModel[]>> {
        return this.httpClient.post<GenericResponseDto<DiscussionModel[]>>(
            `${this.apiBaseUrl}`,
            data,
            this.httpOptions
        );
    }

    getDiscussionDetail(data: any): Observable<GenericResponseDto<DiscussionModel>> {
        return this.httpClient.post<GenericResponseDto<DiscussionModel>>(
            `${this.apiBaseUrl}GetDiscussionById`,
            data,
            this.httpOptions
        );
    }

    deleteDiscussionByAccountNumber(data: any):
    Observable<GenericResponseDto<any>> {
        return this.httpClient.post<GenericResponseDto<any>>(
            `${this.apiBaseUrl}DeleteDiscussionById`,
            data,
            this.httpOptions
        );
    }

    addComment(data: CommentModel): Observable<GenericResponseDto<CommentModel>> {
        return this.httpClient.post<GenericResponseDto<CommentModel>>(
            `${this.apiBaseUrl}AddOrUpdateComment`,
            data,
            this.httpOptions
        );
    }

    getVendors(): Observable<GenericResponseDto<any>> {
        return this.httpClient.get<GenericResponseDto<any>>(
            `${this.apiBaseUrl}GetVendors`,
            this.httpOptions
        );
    }

    getNotifications(accountNumber: string): Observable<GenericResponseDto<any>> {
        const vendorAccountNumber = accountNumber ? `?accountNumber=${accountNumber}` : "";
        return this.httpClient.get<GenericResponseDto<any>>(
            `${this.apiBaseUrl}GetNotifications${vendorAccountNumber}`,
            this.httpOptions
        );
    }
}
