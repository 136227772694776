<div>
  <div class="col-lg-12 pb-2">
    @if(!routerObject.url.includes("summary") && (vendorNotes && actionType === actionTypeEnum.Feedback || userRole
    === 'Internal')) {
    <div>
      @if(userRole !== "Vendor" && actionType === actionTypeEnum.Review) {
      <p-card>
        <div class="row mb-3 mt-3">
          <div class="col-lg-6">
            <h3>Internal Notes</h3>
            <p-editor [style]="{ height: '250px' }" (onTextChange)="onChangeInternalNotes($event)"
              [(ngModel)]="internalNotes"></p-editor>

          </div>
          <div class="col-lg-6">
            <h3>Vendor Notes</h3>
            <p-editor [style]="{ height: '250px' }" (onTextChange)="onChangeVendorNotes($event)"
              [(ngModel)]="vendorNotes" [readonly]="userRole === 'Vendor'"></p-editor>
          </div>
          <p-button label="Save" class="mt-4 d-flex justify-content-end" severity="secondary" (click)="onSaveNotes()">
          </p-button>
        </div>
      </p-card>
      }
    </div>
    }
    <div class="col-lg-12 mt-4 summery-btn-btm">
      <div class="row d-flex justify-content-between">
        <!--@if(!routerObject.url.includes("summary")) {-->
        <div class="col-lg-6 col-12 gap-3 d-flex mb-2">
          <!-- <p-button label="Print Vendor Application" severity="secondary" [outlined]="true"></p-button>
          <p-button label="Download All Documents" severity="secondary" [outlined]="true"></p-button> -->
        </div>
        <!--}-->
        @if(routerObject.url.includes("summary") ) {
        @if(userRole === LoggedInUserRole.Internal) {
        <div class="col-lg-6 gap-3 d-flex justify-content-end mb-2">
          <p-button label="Back" class="buttonbg-white" severity="secondary" (click)="onClickBackButton()"
            [outlined]="true"></p-button>
          <p-button [disabled]="actionType === actionTypeEnum.ApprovalInProgress" (click)="showDialog()"
            label="Send Feedback" severity="secondary"></p-button>
          <p-button [disabled]="actionType === actionTypeEnum.ApprovalInProgress" label="Approve" severity="secondary"
            (click)="onApprove()" class="successful-btn"></p-button>
          <p-button [disabled]="actionType === actionTypeEnum.ApprovalInProgress" label="Reject" severity="secondary"
            (click)="rejectPopup()"></p-button>
        </div>
        } @else {
        <div class="col-lg-6 gap-3 d-flex justify-content-end mb-2">
          <p-button label="Back" class="buttonbg-white" severity="secondary" (click)="onClickBackButton()"
            [outlined]="true">
          </p-button>
          @if(actionType === actionTypeEnum.Review || actionType === actionTypeEnum.ApprovalInProgress) {
          <p-button
            [disabled]="actionType === actionTypeEnum.Review || actionType === actionTypeEnum.ApprovalInProgress"
            label="Submit" severity="success">
          </p-button>
          } @else {
          <p-button label="Submit" severity="success" (click)="confirmSubmitForReview()">
          </p-button>
          }

        </div>
        }
        }

        <div class="col-sm-auto col-12 gap-3 d-flex mb-2">
          <!--@if(!routerObject.url.includes("dashboard") && !routerObject.url.includes("vendor-information")) {
          <p-button label="Proceed to Review" class="buttonbg-white" severity="secondary" (click)="onClickBackButton()" [outlined]="true">
          </p-button>
          }-->
          @if(!routerObject.url.includes("summary") && (!routerObject.url.includes("vendor-information") ||
          userRole === LoggedInUserRole.Internal) && (actionType === actionTypeEnum.Review || actionType ===
          actionTypeEnum.ApprovalInProgress)) {
          <p-button label="Proceed to Review" class="successful-btn" severity="secondary" (click)="onClickNextButton()">
          </p-button>
          }
          @if(routerObject.url.includes("vendor-information") && (userRole === LoggedInUserRole.Vendor || userRole ===
          LoggedInUserRole.SubVendor)) {
          @if(actionType === actionTypeEnum.Review) {
          <p-button label="Proceed to Review" [disabled]="actionType === actionTypeEnum.Review" severity="secondary">
          </p-button>
          } @else {
          <p-button label="Proceed to Review" class="successful-btn" [disabled]="actionType === actionTypeEnum.Review"
            (click)="onSaveAndContinue()" severity="secondary">
          </p-button>
          }

          }
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Feedback For Vendor" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }"
  [draggable]="false" [resizable]="false" class="summery-popup-feedback">

  <p class="mt-4">These following feedbacks will be send to corresponding vendor</p>
  @for(feedbackObject1 of feedbackObject; track feedbackObject1.feedbackType) {
  @if(feedbackObject1.selfNotes) {
  <div class="col-lg-12 border p-3 mb-4 sum-list">
    <h4>{{feedbackObject1.feedbackType}}</h4>
    <p class="mb-0" [innerHTML]="feedbackObject1.selfNotes"></p>
    <!-- <p class="p-0 m-0"><b>Sage Number, Middle Name, Phone Number, Fax Number, Dunn & Bradstreet</b></p> -->
  </div>
  }
  }

  <div class="col-auto d-flex gap-4 mb-4 justify-content-end">
    <p-button label="Back" [raised]="true" severity="secondary" (click)="onClickBack()"></p-button>
    <p-button label="Send Feedback" [raised]="true" severity="secondary" (click)="onClickSendFeedback()"></p-button>
  </div>
</p-dialog>

@if(inprogress) {
<div
  class="col-lg-12 position-fixed start-0 top-0 d-flex justify-content-center align-items-center color-box spinner-contr">
  <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4"></p-progressSpinner>
</div>
}
<p-confirmDialog></p-confirmDialog>
<div class="email-temp-popup">
  <p-dialog header="Email Template" [(visible)]="showEmailTemplate" [style]="{width: '70vw', height: '70vw'}"
    class="activity-popup" [modal]="true">
    <div class="custom-container">
      <div class="row mb-3 mt-5">
        <div class="col-lg-12 pb-2 d-flex justify-content-between">
          <div class="col-auto d-flex gap-4">
          </div>
        </div>
        <div [formGroup]="emailTemplateForm" class="col-lg-12 m-auto">
          <p-card>
            <div class="col-lg-12">
              <div class="row">

                <div class="col-lg-12">
                  <span class="p-float-label">
                    <input pInputText id="Subject" class="w-100" [formControlName]="EmailTemplateEnum.EmailSubject"
                      type="text" name="emailSubject" />
                    <label for="username">Subject</label>
                  </span>
                </div>
              </div>
            </div>
          </p-card>
          <div class="col-lg-12 mt-5">
            <h4>Template</h4>
          </div>
          <p-card>
            <div class="col-lg-12">
              <div class="row">
                <h4>Email Text</h4>
                <div class="col-lg-12">
                  <p-editor [style]="{ height: '150px' }" [formControlName]="EmailTemplateEnum.EmailBody">
                  </p-editor>
                </div>
              </div>
            </div>

          </p-card>
        </div>
        <p-button class="mt-3 mb-3" label="Send Email" severity="secondary" (click)="onReject()">
        </p-button>
      </div>
    </div>
    @if(inprogress) {
    <div
      class="col-lg-12 position-fixed top-0 start-0 d-flex justify-content-center align-items-center color-box spinner-contr">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4"></p-progressSpinner>
    </div>
    }
  </p-dialog>
</div>
<p-dialog (onHide)="backTo()" header="" [(visible)]="toggleApprovalDialog"
          [style]="{width: '40vw', height: '45vh'}" [modal]="true">
  <div class="signup-check-icon bg-white p-5 rounded border text-center">
    <i class="pi pi-check-circle mb-3"></i>
    <h3 class="p-0">We are working on your application of approval process you will be notified shortly after the process is completed.</h3>
    <a class="bck-dashboard p-3 rounded" routerLink="/dashboard">
      Go Back to Dashboad
    </a>
  </div>
</p-dialog>
