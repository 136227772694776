@if(LoggedInUserRole === UserRole.Vendor || routerObject.url.includes("/vendor-information/")){
<div class="nav-bg">
  <div class="custom-container-splitbtn">
    <div class="row m-gap d-flex justify-content-between">
      <!-- <div class="col-lg-9 d-flex d-md-block">
          <ul class="nav tab-bar flex-nowrap overflow-auto-md text-nowrap active">
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      [routerLink]="dashboardLink" routerLinkActive="active-link">Dashboard</a>
              </li>
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      [routerLink]="informationRouterLink" routerLinkActive="active-link"
                      [queryParams]="informationQueryParams">Vendor
                      Application</a>
              </li>
              @if(LoggedInUserRole !== UserRole.Internal) {
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="/vendor/vendor-invoices" routerLinkActive="active-link">Invoices</a>
              </li>
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="/vendor/reporting" routerLinkActive="active-link">Reporting</a>
              </li>
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="/vendor/event-management" routerLinkActive="active-link">Events</a>
              </li>
              <li class="nav-item">
                  <a class="p-3 lead d-flex align-items-center gap-2 link-secondary" href="javascript:;"
                      routerLink="vendor/specials-management" routerLinkActive="active-link">Specials</a>
              </li>
              }
          </ul>
      </div> -->
      <div class="col-auto d-grid gap-1 tab-btn">
        @if(LoggedInUserRole !== UserRole.Internal) {
        <div class="d-flex align-items-center justify-content-end  tabbing-button tabbing-button-vendor">
          <div class="p-buttonset d-flex gap-1">
            <!--<button routerLink="/vendor/rebate-rates" pButton type="button" label="Rebates">
                        </button>-->
            <!-- <button pButton type="button" label="Specials" routerLink="vendor/specials-management">
            </button> -->

            <button pButton routerLink="/vendor/manage-user" type="button" label="Manage Users" class="w-100">
            </button>
            <button pButton routerLink="/vendor/rebate-rates" type="button" label="Rebates" class="w-100">
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
}
@else if(LoggedInUserRole === UserRole.Internal) {
<div class="nav-bg">
  <div class="custom-container-splitbtn">
    <div class="row m-gap d-flex justify-content-between">
      <div class="col-lg-12 d-grid gap-1 tab-btn">
        <div class="d-flex align-items-center justify-content-center  tabbing-button">
          <div class="p-buttonset d-flex gap-1 w-100">

            <button pButton routerLink="/document/templates" type="button" label="Templates">
            </button>
            <button pButton routerLink="/configuration/configuration" type="button" label="Configuration">
            </button>
            <button pButton routerLink="/internal/user-management-listing" type="button" label="Users">
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
}