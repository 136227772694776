<div class="flex justify-content-center notification-des">

  <div class="mb-2 mt-2 d-flex justify-content-between">
    <label class="noti-heading text-color-secondary">
      @if(notificationType === "badge_notification"){
      <p-badge [value]=" " class="noti-bullet"></p-badge>
      } {{ notification }}
    </label>
    <label class="noti-time text-color-secondary fw-bolder">{{ notificationTime | date}}</label>
  </div>
  <label class="noti-description text-color-secondary w-100 p-3" [ngClass]="!isProcessed? 'noti-background' : ''"
    [innerHTML]="notificationDescription"></label>
</div>