export enum ActionType {
    Approved = "Approved",
    Rejected = "Rejected",
    Deferred = "Deferred",
    Feedback = "Feedback",
    Submitted = "Submitted",
    Initiated = "Initiated",
    Review = "Review",
    ApprovalInProgress = "ApprovalInProgress"
}
