import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { AllEmailTemplate } from "../../../modules/document-templates/components/document-templates/models/all-email-template.model";
import { EmailTemplate } from "../../../modules/document-templates/components/document-templates/models/email-template.model";
import { SendEmail } from "../../../modules/document-templates/components/document-templates/models/send-email.model";
import { UpdateEmailTemplate } from "../../../modules/document-templates/components/document-templates/models/update-email-template.model";
import { handleError } from "../../helper/http-helpers";
import { GenericResponseDto } from "../../models/genericResponseDto.model";

@Injectable({
    providedIn: "root"
})
export class ConfigurationTemplateService {
    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    private baseUrl = environment.apiBaseUrl;
    private configurationTemplateRelativeUrl = `/Email`;

    constructor(private http: HttpClient) { }

    getEmailTemplateByTemplateName(templateName: string | null, emailTemplateType: string):
    Observable<GenericResponseDto<EmailTemplate>> {
        return this.http
            .get<
        GenericResponseDto<EmailTemplate>
        >(`${this.baseUrl}${this.configurationTemplateRelativeUrl}/GetEmailTemplate?emailTemplateName=${templateName}&&emailTemplateType=${emailTemplateType}`)
            .pipe(catchError(handleError));
    }

    updateEmailTemplate(data: UpdateEmailTemplate): Observable<GenericResponseDto<EmailTemplate>> {
        return this.http.post<GenericResponseDto<EmailTemplate>>(
            `${this.baseUrl}${this.configurationTemplateRelativeUrl}/UpdateEmailTemplate`,
            data,
            this.httpOptions
        );
    }

    sendEmail(data: SendEmail): Observable<GenericResponseDto<EmailTemplate>> {
        return this.http.post<GenericResponseDto<EmailTemplate>>(
            `${this.baseUrl}${this.configurationTemplateRelativeUrl}/SendSampleEmail`,
            data,
            this.httpOptions
        );
    }

    getAllEmailTemplates(): Observable<GenericResponseDto<AllEmailTemplate[]>> {
        return this.http
            .get<
        GenericResponseDto<AllEmailTemplate[]>
        >(`${this.baseUrl}${this.configurationTemplateRelativeUrl}/GetAllEmailTemplates`)
            .pipe(catchError(handleError));
    }
}
