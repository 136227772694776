import { Component, Input } from "@angular/core";

@Component({
    selector: "app-pdf-viewer",
    templateUrl: "./pdf-viewer.component.html",
    styleUrl: "./pdf-viewer.component.scss",
})
export class PdfViewerComponent {
    @Input() blob!: Blob;
    @Input() zoom = "page-fit";
}
