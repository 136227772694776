import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { StorageService } from "../../../../core/helper/storage.service";
import { NotificationContext } from "../../../../core/models/Enums/notificationContext.enum";
import { ResponseStatus } from "../../../../core/models/Enums/responseStatus.enum";
import { UserRole } from "../../../../core/models/Enums/userRole.enum";
import { GenericResponseDto } from "../../../../core/models/genericResponseDto.model";
import { TotalUnViewedRecords } from "../../../../core/models/totalUnviewedResponse.model";
import { NotificationService } from "../../../../core/services/vendor/notification.service";
import { Notification } from "../../../../shared/models/notification.model";

@Component({
    selector: "app-vendor-notification",
    standalone: false,
    templateUrl: "./vendor-notification.component.html",
    styleUrl: "./vendor-notification.component.scss"
})
export class VendorNotificationComponent implements OnInit {
    notificationContextEnum = [
        {
            Id: 1,
            Name: NotificationContext.VendorInvite,
        },
        {
            Id: 2,
            Name: NotificationContext.VendorMFA,
        },
        {
            Id: 3,
            Name: NotificationContext.VendorApplication,
        },
        {
            Id: 4,
            Name: NotificationContext.VendorFeedback,
        },
        {
            Id: 5,
            Name: NotificationContext.AdminFeedback,
        },
        {
            Id: 6,
            Name: NotificationContext.UserEvent
        },
        {
            Id: 7,
            Name: NotificationContext.Special
        }
    ];
    userRole = "";
    accountNumber = "";

    notificationCount: string = "";

    constructor(
        private notificationService: NotificationService,
        private storageService: StorageService,
        private router: Router
    ) {

    }

    notifications: any = [];

    ngOnInit() {
        const userDetails: string | null = this.storageService.get("userDetails");
        const { userRole } = JSON.parse(userDetails ?? "");
        this.userRole = userRole;
        const accountNumber = JSON.parse(userDetails ?? "").vendorAccountNumber;
        this.accountNumber = accountNumber;
        if (userRole === UserRole.Vendor || userRole === UserRole.SubVendor) {
            this.getNotificationsByAccountNumber(accountNumber);
        }

        if (userRole === UserRole.Internal) {
            this.getNotificationsForInternal();
        }
    }

    getNotificationsByAccountNumber(accountNumber: string) {
        this.notificationService.getNotificationsByAccountNumber(accountNumber)
            .subscribe((response: TotalUnViewedRecords<Notification[]>) => {
                if (response.status === ResponseStatus.Success) {
                    this.notifications = response.data;
                    this.notificationCount = response.totalUnViewedRecords?.toString();
                }
            });
    }

    getNotificationsForInternal() {
        this.notificationService.getNotificationsForInternal()
            .subscribe((response: TotalUnViewedRecords<Notification[]>) => {
                if (response.status === ResponseStatus.Success) {
                    this.notifications = response.data;
                    this.notificationCount = response.totalUnViewedRecords?.toString();
                }
            });
    }

    notificationType: string = "simple_notification";

    onClickNotification(
        accountNumber: string,
        notificationId: number,
        notificationContextId: number,
        isActionable: boolean,
        isProcessed: boolean
    ) {
        if (!isProcessed) {
            this.notificationService.updateNotification(notificationId)
                .subscribe((response: GenericResponseDto<Notification>) => {
                    if (response.status === ResponseStatus.Success) {
                        if (this.userRole === UserRole.Internal) { this.getNotificationsForInternal(); } else {
                            this.getNotificationsByAccountNumber(this.accountNumber);
                        }
                    }
                });
        }
        if (!isActionable) {
            return;
        }
        const selectedNotificationContext = this.notificationContextEnum.find((item: any) => {
            if (item.Id === notificationContextId) {
                return item;
            }

            return null;
        });
        if (!selectedNotificationContext) {
            return;
        }
        const selectedNotificationContextValue = selectedNotificationContext.Name;
        switch (selectedNotificationContextValue) {
            case NotificationContext.VendorApplication:
                if (this.userRole === UserRole.Internal) {
                    this.router.navigate(
                        ["vendor/vendor-information"],
                        { queryParams: { accountNumber } }
                    );
                } else {
                    this.router.navigate(
                        ["vendor/vendor-information"],
                        { queryParams: { } }
                    );
                }
                break;
            case NotificationContext.VendorFeedback:
                if (this.userRole === UserRole.Internal) {
                    this.router.navigate(
                        ["vendor/summary"],
                        { queryParams: { accountNumber } }
                    );
                } else {
                    this.router.navigate(
                        ["vendor/summary"],
                        { queryParams: { } }
                    );
                }
                break;
            case NotificationContext.UserEvent:
                if (this.userRole === UserRole.Internal) {
                    this.router.navigate(
                        ["vendor/event-management"],
                        { queryParams: { accountNumber } }
                    );
                } else {
                    this.router.navigate(
                        ["vendor/event-management"],
                        { queryParams: { } }
                    );
                }
                break;
            case NotificationContext.Special:
                if (this.userRole === UserRole.Internal) {
                    this.router.navigate(
                        ["vendor/specials-management"],
                        { queryParams: { accountNumber } }
                    );
                } else {
                    this.router.navigate(
                        ["vendor/specials-management"],
                        { queryParams: { } }
                    );
                }
                break;
            default:
                if (this.userRole === UserRole.Internal) {
                    this.router.navigate(
                        ["vendor/vendor-information"],
                        { queryParams: { accountNumber } }
                    );
                } else {
                    this.router.navigate(
                        ["vendor/vendor-information"],
                        { queryParams: { } }
                    );
                }
        }
    }
}
