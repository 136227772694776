import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { Notification } from "../../../shared/models/notification.model";
import { GenericResponseDto } from "../../models/genericResponseDto.model";
import { TotalUnViewedRecords } from "../../models/totalUnviewedResponse.model";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    apiBaseUrl = `${environment.apiBaseUrl}/notification/`;

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    constructor(private httpClient: HttpClient) { }

    getNotificationsByAccountNumber(accountNumber: string):
    Observable<TotalUnViewedRecords<Notification[]>> {
        return this.httpClient.get<TotalUnViewedRecords<Notification[]>>(
            `${this.apiBaseUrl}${accountNumber}/Drawer`,
            this.httpOptions
        );
    }

    getNotificationsForInternal():
    Observable<TotalUnViewedRecords<Notification[]>> {
        return this.httpClient.get<TotalUnViewedRecords<Notification[]>>(
            `${this.apiBaseUrl}internal/Drawer`,
            this.httpOptions
        );
    }

    updateNotification(id: number):
    Observable<GenericResponseDto<Notification>> {
        return this.httpClient.put<GenericResponseDto<Notification>>(
            `${this.apiBaseUrl}${id}`,
            this.httpOptions
        );
    }
}
