export enum EmailTypeNames {
    NewVendorInvitation = "NewVendorInvitation",
    ExistingVendorInvitation = "ExistingVendorInvitation",
    SubVendorInvitation = "SubVendorInvitation",
    MFACode = "MFACode",
    WorkFlow = "WorkFlow",
    RegistrationCompleteToVendor = "RegistrationCompleteToVendor",
    WorkFlowReviewToNadel = "WorkFlowReviewToNadel",
    EventByVendorCreatedOrUpdatedToInternalUser = "EventByVendorCreatedOrUpdatedToInternalUser",
    VendorActionOnEventToNadel = "VendorActionOnEventToNadel",
    EventByInternalUserCreatedOrUpdatedToVendor = "EventByInternalUserCreatedOrUpdatedToVendor",
    InternalUserActionOnEventToVendor = "InternalUserActionOnEventToVendor",
    RegistrationCompleteToNadel = "RegistrationCompleteToNadel",
    WorkflowReviewToVendor = "WorkflowReviewToVendor",
    SpecialByVendorCreatedOrUpdatedToNadel = "SpecialByVendorCreatedOrUpdatedToNadel",
    SpecialApprovedByInternalUserToVendor = "SpecialApprovedByInternalUserToVendor",
    EventCancelledUser = "EventCancelledUser",
    DiscussionTopic = "DiscussionTopic",
    DiscussionTopicChat = "DiscussionTopicChat",
    DocumentUpdate = "DocumentUpdate",
    Feedback = "Feedback"
}
