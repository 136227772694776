import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { Activity } from "../../../modules/vendor/components/activity-stream/models/activity.model";
import { Feedback } from "../../../shared/models/feedback.model";
import { SummaryPost } from "../../../shared/models/summary-post.model";
import { Workflow } from "../../../shared/models/workflow.model";
import { GenericResponseDto } from "../../models/genericResponseDto.model";

@Injectable({
    providedIn: "root"
})
export class WorkflowService {
    private apiBaseUrl = `${environment.apiBaseUrl}/workflow/`;

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    updateActivityStream = "";
    refresh = "";

    private updateActivityStream$: BehaviorSubject<string> = new BehaviorSubject(this.updateActivityStream);
    private refresh$: BehaviorSubject<string> = new BehaviorSubject(this.refresh);

    getActivityStreamUpdates(): Observable<string> {
        return this.updateActivityStream$.asObservable();
    }

    setActivityStreamUpdates(value: string) {
        this.updateActivityStream$.next(value);
    }

    getRefreshedWorkflow(): Observable<string> {
        return this.refresh$.asObservable();
    }

    setRefreshedWorkflow(value: string) {
        this.refresh$.next(value);
    }

    constructor(private httpClient: HttpClient) { }

    getAllReviews(data: any): Observable<GenericResponseDto<Workflow>> {
        return this.httpClient.post<GenericResponseDto<Workflow>>(
            `${this.apiBaseUrl}review`,
            data,
            this.httpOptions
        );
    }

    workflowSummaryPost(data: SummaryPost): Observable<GenericResponseDto<SummaryPost>> {
        return this.httpClient.post<GenericResponseDto<SummaryPost>>(
            `${this.apiBaseUrl}summary`,
            data,
            this.httpOptions
        );
    }

    getWorkflowByAccountNumber(accountNumber: string): Observable<GenericResponseDto<Workflow>> {
        return this.httpClient.get<GenericResponseDto<Workflow>>(
            `${this.apiBaseUrl}feedbacks?accountNumber=${accountNumber}`,
            this.httpOptions
        );
    }

    getAllActivitiesByAccountNumber(accountNumber: string): Observable<GenericResponseDto<Activity[]>> {
        return this.httpClient.get<GenericResponseDto<Activity[]>>(
            `${this.apiBaseUrl}activities?accountNumber=${accountNumber}`,
            this.httpOptions
        );
    }

    postWorkflowFeedback(data: Feedback) : Observable<GenericResponseDto<string>> {
        return this.httpClient.post<GenericResponseDto<string>>(
            `${this.apiBaseUrl}feedback`,
            data,
            this.httpOptions
        );
    }

    getCurrentWorkflowByAccountNumber(accountNumber: string): Observable<GenericResponseDto<Workflow>> {
        return this.httpClient.get<GenericResponseDto<Workflow>>(
            `${this.apiBaseUrl}GetCurrentWorkflow?vendorAccountNumber=${accountNumber}`,
            this.httpOptions
        );
    }

    ExportInvitesToExcel(data: any = {}): Observable<Blob> {
        return this.httpClient.post<Blob>(`${this.apiBaseUrl}export/review`, data, { responseType: "blob" as "json" });
    }
}
