import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[jni-phone-number-formatting]"
})
export class PhoneMaskDirective {
    constructor(public ngControl: NgControl) { }

    @HostListener("ngModelChange", ["$event"])
    onModelChange(event: any) {
        this.onInputChange(event, false);
    }

    @HostListener("keydown.backspace", ["$event"])
    keydownBackspace(event: any) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event: any, backspace: any) {
        if (event) {
            if (event.includes("+")) {
                this.getRegexValidationForInternational(event, backspace);
            } else {
                this.getRegexValidationForNorthAmerica(event, backspace);
            }
        }
    }

    getRegexValidationForNorthAmerica(event: any, backspace: any) {
        let phoneNumberValue = event.length > 14 ? event : event.replace(/\D/g, "");
        if (event.length > 14) {
            let usaPhoneNumber = event.substring(0, 14);
            usaPhoneNumber = usaPhoneNumber.replace(/\D/g, "");
            const phoneExtension = event.substr(14);
            const newPhoneExtension = phoneExtension.replace(/[^a-zA-Z0-9 ]/, "");
            phoneNumberValue = usaPhoneNumber + newPhoneExtension.trimStart();
        }
        if (backspace && phoneNumberValue.length <= 6) {
            phoneNumberValue = phoneNumberValue.substring(
                0,
                phoneNumberValue.length - 1
            );
        }
        if (phoneNumberValue.length === 0) {
            phoneNumberValue = "";
        } else if (phoneNumberValue.length <= 3) {
            phoneNumberValue = phoneNumberValue.replace(/^(\d{0,3})/, "($1)");
        } else if (phoneNumberValue.length <= 6) {
            phoneNumberValue = phoneNumberValue.replace(
                /^(\d{0,3})(\d{0,3})/,
                "($1) $2"
            );
        } else if (phoneNumberValue.length <= 10) {
            phoneNumberValue = phoneNumberValue.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
            );
        } else {
            phoneNumberValue = phoneNumberValue.substring(0, 30);
            phoneNumberValue = phoneNumberValue.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,20})/,
                "($1) $2-$3 $4"
            );
        }
        this.ngControl?.valueAccessor?.writeValue(phoneNumberValue);
    }

    getRegexValidationForInternational(event: any, backspace: any) {
        let phoneNumberValue = event.replace(/[^0-9+]/g, "");
        if (backspace && phoneNumberValue.length <= 6) {
            phoneNumberValue = phoneNumberValue.substring(
                0,
                phoneNumberValue.length - 1
            );
        }
        if (phoneNumberValue.length === 0) {
            phoneNumberValue = "";
        } else {
            phoneNumberValue = phoneNumberValue.substring(0, 14);
            if (phoneNumberValue.length >= 12) {
                const phoneNumberValueCopy = phoneNumberValue;
                const lastTenDigits = phoneNumberValue.substr(
                    phoneNumberValue.length - 10
                );
                const firstThreeDigits = lastTenDigits.substring(0, 3);
                const secondThreeDigits = lastTenDigits.substring(3, 6);
                const lastFourDigits = lastTenDigits.substring(6, 10);
                const startDigits = phoneNumberValueCopy.slice(0, -10);
                phoneNumberValue = `${startDigits
                } ${
                    firstThreeDigits
                } ${
                    secondThreeDigits
                } ${
                    lastFourDigits}`;
            }
        }

        this.ngControl?.valueAccessor?.writeValue(phoneNumberValue);
    }
}
