import { DOCUMENT, Location } from "@angular/common";
import {
    Component, EventEmitter, HostListener, Inject, Input, OnInit, Output
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    MsalService,
} from "@azure/msal-angular";

import { StorageService } from "../../../core/helper/storage.service";
import { ResponseStatus } from "../../../core/models/Enums/responseStatus.enum";
import { UserRole } from "../../../core/models/Enums/userRole.enum";
import { GenericResponseDto } from "../../../core/models/genericResponseDto.model";
import { DiscussionsService } from "../../../core/services/discussions.service";
import { ThemeService } from "../../../core/services/theme.service";
import { AccountDetailService } from "../../../core/services/vendor/account-detail.service";

@Component({
    selector: "app-header",
    standalone: false,
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss"
})
export class HeaderComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private themeService: ThemeService,
        private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private discussionService: DiscussionsService,
        private accountDetailsService: AccountDetailService,
        private storageService: StorageService,
        private authService: MsalService,
    ) {

    }
    accountNumber = "";
    summaryRouterLink = "";
    dashboardLink = "";
    informationRouterLink = "";
    summaryQueryParams = {};
    informationQueryParams = {};
    userRole = UserRole;
    checked: boolean = false;
    currentUser: string = "";
    currentRole: string = "";
    items: any = [];
    homeRouterLink = "/";
    internalRole: UserRole = UserRole.Internal;
    notifications = 0;
    loggedInUserRole = "";
    companyName = "";
    @Input() multipleVendors: any = [];
    @Output() vendorSelected: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit() {
        this.summaryRouterLink = "/vendor/summary";
        this.informationRouterLink = "/vendor/vendor-information";
        this.dashboardLink = "/vendor/dashboard";
        const userDetails: string | null = this.storageService.get("userDetails");

        if (userDetails) {
            const { email, userRole, vendorAccountNumber } = JSON.parse(userDetails);
            const [username] = email?.split("@") ?? [];
            this.currentUser = username ?? this.currentUser;
            this.currentRole = userRole ?? "";
            this.loggedInUserRole = userRole;
            this.accountNumber = vendorAccountNumber;

            if (userRole === UserRole.Internal) {
                this.route.queryParamMap.subscribe((param: any) => {
                    const { accountNumber } = param.params;
                    this.accountNumber = accountNumber;
                    this.summaryQueryParams = { accountNumber };
                    this.informationQueryParams = { accountNumber };
                });
                this.dashboardLink = "dashboard";
            }

            this.accountDetailsService.companyName.subscribe((response: string) => {
                this.companyName = response;
            });
        } else {
            this.currentUser = this.currentUser ?? "";
            this.currentRole = "";
            this.loggedInUserRole = "";
            this.summaryRouterLink = "/vendor/summary";
            this.informationRouterLink = "/vendor/vendor-information";
            this.dashboardLink = "/vendor/dashboard";
        }

        this.getNotifications();
        this.items = [
            {
                label: "Logout",
                icon: "pi pi-sign-out",
                command: () => {
                    this.logout();
                }
            }
        ];

        this.discussionService.notificationCount$.subscribe((count) => {
            this.notifications = count;
        });
    }

    switchTheme() {
        const themeLink = this.document.getElementById("app-theme") as HTMLAnchorElement;

        if (themeLink.href.includes("nadel-lite")) {
            if (this.checked) { this.changeTheme("nadel-lite"); } else {
                this.changeTheme("nadel-dark");
            }
        }

        if (themeLink.href.includes("nadel-dark")) {
            if (this.checked) { this.changeTheme("nadel-lite"); } else {
                this.changeTheme("nadel-dark");
            }
        }
    }

    changeTheme(theme: string) {
        this.themeService.switchTheme(theme);
    }

    onClickBack() {
        this.location.back();
    }

    logout(popup?: boolean) {
        if (popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: "/",
            });
        } else {
            sessionStorage.removeItem("userDetails");
            this.authService.logoutRedirect();
        }
    }

    getNotifications() {
        this.discussionService.getNotifications(this.accountNumber)
            .subscribe((response: GenericResponseDto<any[]>) => {
                this.notifications = 0;
                if (response.status === ResponseStatus.Success) {
                    if (!response.data || response.data.length < 1) {
                        this.notifications = 0;
                    } else {
                        this.notifications = response.data.length;
                    }
                }
            });
    }
    isDropdownOpen = false;

    @HostListener("document:click", ["$event"])
    handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (this.isDropdownOpen && !target.closest(".dropdown-container>a")) {
            this.isDropdownOpen = false;
        }
    }

    toggleDropdown(event: MouseEvent) {
        event.preventDefault(); // Prevents the default anchor behavior
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    triggerVendorSelection(accountNumber: string) {
        this.vendorSelected.emit(accountNumber);
    }
}
