import { Component, Input } from "@angular/core";

@Component({
    selector: "app-badge-notifications",
    standalone: false,
    templateUrl: "./badge-notifications.component.html",
    styleUrl: "./badge-notifications.component.scss"
})
export class BadgeNotificationsComponent {
    @Input() notification: string = "";
    @Input() notificationDescription: string = "";
    @Input() notificationType: string = "";
    @Input() notificationComponent: boolean = false;
    @Input() notificationTime: string = "";
    @Input() isProcessed = true;
}
