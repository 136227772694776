import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";

import { environment } from "../../../../environments/environment";
import { AccountDetails } from "../../../modules/vendor/components/vendor-information/vendor-information/models/account-details.model";
import { GenericResponseDto } from "../../models/genericResponseDto.model";

@Injectable({
    providedIn: "root"
})

export class AccountDetailService {
    apiBaseUrl = `${environment.apiBaseUrl}/Vendor/`;
    companyName = new BehaviorSubject("");
    currentQuote = this.companyName.asObservable();

    accountDetails = new BehaviorSubject<any>(null);
    data: Observable<any> = this.accountDetails.asObservable();

    updateCompanyName(newCompanyName: string) {
        this.companyName.next(newCompanyName);
    }

    constructor(private httpClient: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    getAccountDetails(id: string): Observable<GenericResponseDto<AccountDetails>> {
        return this.httpClient
            .get<GenericResponseDto<AccountDetails>>(`${this.apiBaseUrl}${id}`, this.httpOptions)
            .pipe(
                tap((data) => this.accountDetails.next(data))
            );
    }

    updateAccountDetails(data: AccountDetails):
    Observable<GenericResponseDto<AccountDetails>> {
        return this.httpClient.put<GenericResponseDto<AccountDetails>>(
            `${this.apiBaseUrl}`,
            data,
            this.httpOptions
        );
    }

    deleteFreeText(vendorAccountNumber: string, id: number | null):
    Observable<GenericResponseDto<string>> {
        return this.httpClient.delete<GenericResponseDto<string>>(
            `${this.apiBaseUrl}${vendorAccountNumber}/${id}`,
            this.httpOptions
        );
    }
}
