<p-dialog header="Email Template" [(visible)]="showEmailTemplate" (onHide)="closeModal()" [style]="{width: '70vw', height: '70vw'}"
    class="activity-popup" [modal]="true">
    <div class="custom-container">
        <div class="row mb-3 mt-5">
            <div class="col-lg-12 pb-2 d-flex justify-content-between">
                <div class="col-auto d-flex gap-4">
                </div>
            </div>
            <div [formGroup]="emailTemplateForm" class="col-lg-12 m-auto">

                @if(isInviteLog) {
                    <p-card>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4">
                                    <span class="p-float-label">
                                        <input pInputText class="w-100"
                                            [formControlName]="EmailTemplateEnum.FirstNameUpdated" type="text" />
                                        <label for="username">First Name</label>
                                    </span>
                                </div>

                                <div class="col-lg-4">
                                    <span class="p-float-label">
                                        <input pInputText class="w-100"
                                            [formControlName]="EmailTemplateEnum.LastNameUpdated" type="text" />
                                        <label for="username">Last Name</label>
                                    </span>
                                </div>

                                <div class="col-lg-4">
                                    <span class="p-float-label">
                                        <input pInputText class="w-100"
                                            [formControlName]="EmailTemplateEnum.EmailUpdated" type="text"
                                            />
                                        <label for="username">Email</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </p-card>
                }

                <p-card>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <span class="p-float-label">
                                    <input pInputText id="Subject" class="w-100"
                                        [formControlName]="EmailTemplateEnum.EmailSubject" type="text"
                                        name="emailSubject" />
                                    <label for="username">Subject</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </p-card>
                <div class="col-lg-12 mt-5">
                    <h4>Template</h4>
                </div>
                <p-card>
                    <div class="col-lg-12">
                        <div class="row">
                            <h4>Email Text</h4>
                            <div class="col-lg-12">
                                <p-editor [style]="{ height: '150px' }"
                                    [formControlName]="EmailTemplateEnum.EmailBody">
                                </p-editor>
                            </div>
                        </div>
                    </div>
                </p-card>
                <div class="mt-3 mb-3">
                    <p-fileUpload #fileUpload [customUpload]="false" [multiple]="true" [auto]="true"
                        (onRemove)="onRemoveFile($event)" [chooseLabel]="'Email Attachments'"
                        class="drag-file-spacing text-center" (onSelect)="onFileSelected($event)">
                        <ng-template pTemplate="content" let-files>
                            <div class="text-center">Drag & Drop Here</div>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
            <p-button label="Send Email Invite" severity="secondary" (click)="sendEmailInvite()">
            </p-button>
        </div>
    </div>
    <div *ngIf="vendorUsersInProgress" class="col-lg-12 position-fixed top-0 start-0 d-flex justify-content-center align-items-center color-box spinner-contr">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4"></p-progressSpinner>
    </div>
</p-dialog>
