import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    public add(key: string, value: string): void {
        window.sessionStorage.removeItem(key);
        window.sessionStorage.setItem(key, value);
    }

    public get(key: string): string | null {
        return window.sessionStorage.getItem(key);
    }

    public remove(key: string): void {
        window.sessionStorage.removeItem(key);
    }
}
