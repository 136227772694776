import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Feedback } from "../../../shared/models/feedback.model";

@Injectable({
    providedIn: "root"
})
export class FeedbackService {
    feedbackObject: Feedback[] = [];

    private feedbackObs$: BehaviorSubject<Feedback[]> = new BehaviorSubject(this.feedbackObject);

    getVendorInfoFeedback(): Observable<Feedback[]> {
        return this.feedbackObs$.asObservable();
    }

    setVendorInfoFeedback(value: Feedback[]) {
        this.feedbackObs$.next(value);
    }
}
