import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document) { }

    switchTheme(theme: string) {
        const themeLink = this.document.getElementById("app-theme") as HTMLAnchorElement;

        if (themeLink) {
            themeLink.href = `${theme}.css`;
        }
    }
}
