<div class="custom-container new-discussion">
    <div class="row align-items-center screen-hgt text-center">
<div class="col-lg-12">
    <div class="main-bg">
        <img src="assets/images/page-not-found.svg" alt="Discusison">
    </div>
    <h2 class="mb-4 mt-5">404 Page not found</h2>
    <p>
        Seems like your browser got lost in the digital jungle. Let's find your way back <br>to the homepage.
    </p>
   
        <a routerLink="/">Go Back to Dashboard</a>
    

</div>
    </div>
</div>