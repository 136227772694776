import {
    Component, EventEmitter, Input,
    OnInit, Output
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { FileSelectEvent } from "primeng/fileupload";
import { Observable, ReplaySubject } from "rxjs";

import { StorageService } from "../../../../core/helper/storage.service";
import { ActionType } from "../../../../core/models/Enums/actionType.enum";
import { ActionTypes } from "../../../../core/models/Enums/actionTypes.enum";
import { FeedbackType } from "../../../../core/models/Enums/feedbackType.enum";
import { ResponseStatus } from "../../../../core/models/Enums/responseStatus.enum";
import { UserRole } from "../../../../core/models/Enums/userRole.enum";
import { GenericResponseDto } from "../../../../core/models/genericResponseDto.model";
import { ConfigurationTemplateService } from "../../../../core/services/vendor/configuration-template.service";
import { FeedbackService } from "../../../../core/services/vendor/feedback.service";
import { WorkflowService } from "../../../../core/services/vendor/workflow.service";
import { Feedback } from "../../../../shared/models/feedback.model";
import { SummaryPost } from "../../../../shared/models/summary-post.model";
import { Workflow } from "../../../../shared/models/workflow.model";
import { EmailAttachment } from "../../../dashboard/components/vendor-signup/models/email-attachment.model";
import { EmailTemplate } from "../../../dashboard/components/vendor-signup/models/email-template.model";
import { EmailTemplateForm } from "../../../dashboard/components/vendor-signup/models/email-template-form.models";
import { EmailAttachmentEnum } from "../../../dashboard/components/vendor-signup/models/Enums/emailAttachmentEnum.enum";
import { EmailTemplateEnum } from "../../../dashboard/components/vendor-signup/models/Enums/emailTemplateEnum.enum";
import { EmailVariable } from "../../../document-templates/components/document-templates/models/email-variable.model";
import { EmailTemplateType } from "../../../document-templates/components/document-templates/models/Enums/email-template-type.enum";
import { EmailTypeNames } from "../../../document-templates/components/document-templates/models/Enums/email-type-names.enum";

@Component({
    selector: "app-vendor-summery",
    templateUrl: "./vendor-summery.component.html",
    styleUrl: "./vendor-summery.component.scss"
})
export class VendorSummeryComponent implements OnInit {
    @Output() saveAndContinue: EventEmitter<void> = new EventEmitter<void>();
    @Output() currentWorkflowStatus = new EventEmitter<string>();

    @Input() isPreferedPartner! : any;
    @Input() isRebateFilled!: boolean;
    @Input() isDocumentsUploaded!: boolean;

    visible: boolean = false;
    public routerObject;
    userRole = "";
    accountNumber = "";
    vendorInformation = false;
    vendorDocuments = false;
    vendorContacts = false;
    vendorCompliance = false;
    internalNotes? = "";
    vendorNotes? = "";
    navigateBack = "";
    navigateNext = "";
    queryParams = {};
    LoggedInUserRole = UserRole;

    feedbackObject: Feedback[] = [];

    feedbackType = "";
    internalNotesToSave = "";
    vendorNotesToSave = "";

    actionType = "";
    actionTypeEnum = ActionType;

    inprogress: boolean = false;

    showEmailTemplate: boolean = false;
    emailVariables: EmailVariable[] = [];
    listOfAttachments: EmailAttachment[] = [];
    selectedAttachmentIndex: number = 0;
    emailTemplateType = "";
    emailTemplateName = "";
    fileBase64Index = 0;

    EmailTemplateEnum = EmailTemplateEnum;

    timeOutForRedirect: any;
    toggleApprovalDialog: boolean = false;

    UserRole = UserRole;
    @Output() refreshVendorInformation = new EventEmitter<string>();

    emailTemplateForm = this.fb.group<EmailTemplateForm>({
        [EmailTemplateEnum.Name]: this.fb.control<string | null>("", { nonNullable: true }),
        [EmailTemplateEnum.EmailSubject]: this.fb.control<string | null>("", { nonNullable: true }),
        [EmailTemplateEnum.EmailBody]: this.fb.control<string | null>("", { nonNullable: true }),
        [EmailTemplateEnum.Attachments]: this.fb.control<EmailAttachment[] | null>([], { nonNullable: true })
    });

    constructor(
        private router: Router,
        private feedbackService: FeedbackService,
        private workflowService: WorkflowService,
        private storageService: StorageService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private fb: FormBuilder,
        private configurationTemplateService: ConfigurationTemplateService
    ) {
        this.routerObject = router;
    }

    ngOnInit() {
        const userDetails: string | null = this.storageService.get("userDetails");
        const { userRole } = JSON.parse(userDetails ?? "");
        this.userRole = userRole;
        this.setNavigation();
        if (userRole === UserRole.Internal) {
            this.route.queryParamMap.subscribe((param: any) => {
                const accountNumberParam = param.params.accountNumber;
                this.accountNumber = accountNumberParam;
                if (Object.keys(param.params).length === 0) {
                    this.router.navigate(["dashboard"]);
                }
            });
        } else {
            const accountNumber = JSON.parse(userDetails ?? "").vendorAccountNumber;
            this.accountNumber = accountNumber;
        }
        this.getCurrentWorkflow();
        this.feedbackService.getVendorInfoFeedback().subscribe((value: Feedback[]) => {
            this.feedbackObject = value;
        });
        this.initializeComments();

        if (this.router.url.includes("vendor-information")) {
            this.feedbackType = FeedbackType.Vendor_Information;
        }
        this.emailTemplateName = EmailTypeNames.WorkFlow;
        this.emailTemplateType = EmailTemplateType.WorkFlow;
        this.getEmailTemplateByTemplateName();
        // console.log(this.isRebateFilled);
    }

    showDialog() {
        if (this.actionType === this.actionTypeEnum.ApprovalInProgress) {
            return;
        }
        this.visible = true;
    }

    onChangeInternalNotes(eventObj: any) {
        const event = {
            target: {
                value: eventObj.htmlValue
            }
        };
        this.internalNotesToSave = event.target.value;
        /* eslint-disable no-param-reassign */
        if (this.router.url.includes("vendor-information")) {
            if (!this.vendorInformation) {
                this.feedbackObject.push({
                    feedbackType: FeedbackType.Vendor_Information,
                    notesForInitiator: event.target.value,
                    selfNotes: ""
                });
                this.vendorInformation = true;
            } else {
                this.feedbackObject.forEach((item: Feedback) => {
                    if (item.feedbackType === FeedbackType.Vendor_Information) {
                        item.notesForInitiator = event.target.value;
                    }
                    return 0;
                });
            }
            this.feedbackService.setVendorInfoFeedback(this.feedbackObject);
        }
        this.feedbackService.setVendorInfoFeedback(this.feedbackObject);
        /* eslint-enable no-param-reassign */
    }

    onChangeVendorNotes(eventObj: any) {
        const event = {
            target: {
                value: eventObj.htmlValue
            }
        };
        this.vendorNotesToSave = event.target.value;
        /* eslint-disable no-param-reassign */
        if (this.router.url.includes("vendor-information")) {
            if (!this.vendorInformation) {
                this.feedbackObject.push({
                    feedbackType: FeedbackType.Vendor_Information,
                    selfNotes: event.target.value,
                    notesForInitiator: ""
                });
                this.vendorInformation = true;
            } else {
                this.feedbackObject.forEach((item: Feedback) => {
                    if (item.feedbackType === FeedbackType.Vendor_Information) {
                        item.selfNotes = event.target.value;
                    }
                    return 0;
                });
            }
            this.feedbackService.setVendorInfoFeedback(this.feedbackObject);
        }
        /* eslint-enable no-param-reassign */
    }

    onClickBack() {
        this.visible = false;
    }

    onClickSendFeedback() {
        const data: SummaryPost = {
            vendorAccountNo: this.accountNumber,
            actionType: ActionTypes.SendFeedback,
            feedbacks: this.feedbackObject
        };
        this.inprogress = true;
        this.workflowService.workflowSummaryPost(data).subscribe((response:
        GenericResponseDto<SummaryPost>) => {
            this.inprogress = false;
            if (response.status === ResponseStatus.Success) {
                this.getCurrentWorkflow();
                this.messageService.clear();
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Feedback Succesfully Sent"
                });
                this.visible = false;
                // this.router.navigate(["vendor/reviews"]);
                this.redirectTo("vendor/reviews");
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: response.message
                });
            }
        });
    }

    initializeComments() {
        if (this.router.url.includes("vendor-information")) {
            this.feedbackObject.forEach((item: Feedback) => {
                if (item.feedbackType === FeedbackType.Vendor_Information) {
                    this.internalNotes = item.notesForInitiator; this.vendorNotes = item.selfNotes;
                    this.vendorInformation = true;
                }
                return 0;
            });
        }
    }

    redirectFunction() {
        this.router.navigate(["vendor/dashboard"]);
    }

    onClickSubmitReview() {
        const postData: SummaryPost = {
            vendorAccountNo: this.accountNumber,
            actionType: ActionTypes.Review
        };
        this.inprogress = true;
        this.workflowService.workflowSummaryPost(postData).subscribe((response:
        GenericResponseDto<SummaryPost>) => {
            this.inprogress = false;
            if (response.status === ResponseStatus.Success) {
                this.getCurrentWorkflow();
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: response.message
                });

                this.redirectTo("vendor/dashboard");
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: response.message
                });
            }
        });
    }

    confirmSubmitForReview() {
        this.confirmationService.confirm({
            message: "Are you sure you want to submit?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            acceptIcon: "none",
            rejectIcon: "none",
            rejectButtonStyleClass: "p-button-text",
            accept: () => {
                this.onClickSubmitReview();
            },
            reject: () => {
            }
        });
    }

    setNavigation() {
        if (this.userRole === UserRole.Internal) {
            this.route.queryParamMap.subscribe((param: any) => {
                this.queryParams = { accountNumber: param.params.accountNumber };
            });
        } else {
            this.queryParams = {};
        }
        const currentUrl = this.router.url;
        if (currentUrl.includes("dashboard")) {
            this.navigateBack = "/";
            this.navigateNext = "/vendor/vendor-information";
        }
        if (currentUrl.includes("vendor-information")) {
            if (this.userRole === UserRole.Internal) {
                this.navigateBack = "/vendor/dashboard";
            } else {
                this.navigateBack = "/dashboard";
            }
            this.navigateNext = "/vendor/summary";
        }
        if (currentUrl.includes("summary")) {
            this.navigateBack = "/vendor/vendor-information";
            this.navigateNext = "/";
        }
    }

    onApprove() {
        if (this.actionType === this.actionTypeEnum.ApprovalInProgress) {
            return;
        }
        const data: SummaryPost = {
            vendorAccountNo: this.accountNumber,
            actionType: ActionTypes.Approve
        };

        if (this.isPreferedPartner && this.isPreferedPartner === "ALL") {
            this.messageService.clear();
            this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail: "Preferred Partner Level value cannot"
                + " be 'All' when finalizing approvals for vendor application. Please set it to to either PP,"
                + " PPD or DSC."
            });
            this.visible = false;
            return;
        }
        this.inprogress = true;
        this.workflowService.workflowSummaryPost(data).subscribe((response: GenericResponseDto<SummaryPost>) => {
            this.inprogress = false;
            if (response.status === ResponseStatus.Success) {
                this.toggleApprovalDialog = true;
                this.getCurrentWorkflow();
                this.messageService.clear();
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: response.message
                });
                // this.router.navigate(["vendor/reviews"]);
                // this.redirectTo("vendor/reviews");
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: response.message
                });
            }
        });
    }

    onReject() {
        const data: SummaryPost = {
            vendorAccountNo: this.accountNumber,
            actionType: ActionTypes.Reject
        };
        this.inprogress = true;
        const updatedData = {
            ...data,
            emailSubject: this.emailTemplateForm.value[EmailTemplateEnum.EmailSubject],
            emailBody: this.emailTemplateForm.value[EmailTemplateEnum.EmailBody]
        };
        this.workflowService.workflowSummaryPost(updatedData).subscribe((response: GenericResponseDto<SummaryPost>) => {
            this.inprogress = false;
            if (response.status === ResponseStatus.Success) {
                this.getCurrentWorkflow();
                this.messageService.clear();
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: response.message
                });
                // this.router.navigate(["vendor/reviews"]);
                this.redirectTo("vendor/reviews");
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: response.message
                });
            }
        });
    }

    rejectPopup() {
        if (this.actionType === this.actionTypeEnum.ApprovalInProgress) {
            return;
        }
        this.getEmailTemplateByTemplateName();
        this.showEmailTemplate = true;
    }

    onClickBackButton() {
        if (this.navigateBack === "/dashboard" || this.navigateBack === "/vendor/dashboard") {
            this.queryParams = {};
        }
        this.router.navigate(
            [this.navigateBack],
            { queryParams: this.queryParams }
        );
    }

    onClickNextButton() {
        this.router.navigate(
            [this.navigateNext],
            { queryParams: this.queryParams }
        );
    }

    onSaveNotes() {
        const data: Feedback = {
            vendorAccountNo: this.accountNumber,
            selfNotes: this.vendorNotesToSave,
            notesForInitiator: this.internalNotesToSave,
            feedbackType: this.feedbackType
        };
        this.workflowService.postWorkflowFeedback(data).subscribe((response: any) => {
            if (response.status === ResponseStatus.Success) {
                this.messageService.clear();
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Your feedback has been updated."
                });
            } else {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: response.message
                });
            }
        });
    }

    onSaveAndContinue(): void {
        if (!this.isRebateFilled) {
            this.messageService.clear();
            this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail: "You must submit rebates to proceed."
            });
            return;
        }

        if (!this.isDocumentsUploaded) {
            this.messageService.clear();
            this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail: "You must upload all required documents to proceed."
            });
            return;
        }
        this.saveAndContinue.emit();
    }

    copyVariableValue(val: string) {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        const valUpdate = `%${val}%`;
        selBox.value = valUpdate;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    }

    onFileSelected(event: FileSelectEvent) {
        this.fileBase64Index = 0;
        const fileList = event.files;
        if (!fileList) {
            return;
        }
        for (let i = 0; i < fileList.length; i += 1) {
            this.convertFile(fileList[i]).subscribe((base64) => {
                this.listOfAttachments[this.fileBase64Index][EmailAttachmentEnum.Base64] = base64;
                this.fileBase64Index += 1;
                this.emailTemplateForm.patchValue({ [EmailTemplateEnum.Attachments]: this.listOfAttachments });
            });
        }
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));
        this.listOfAttachments.forEach((item: EmailAttachment) => {
            if (item.FileName === file.name) {
                this.messageService.clear();
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Some file(s) are already added and are descarded"
                });
            }
        });
        this.listOfAttachments.push({ [EmailAttachmentEnum.FileName]: file.name });
        return result;
    }

    onRemoveFile(event: any) {
        let indexOfFile = -1;
        this.listOfAttachments.forEach((item: EmailAttachment, index) => {
            if (event.file.name === item.FileName) {
                indexOfFile = index;
            }
        });
        this.listOfAttachments.splice(indexOfFile, 1);
    }

    getEmailTemplateByTemplateName() {
        this.configurationTemplateService
            .getEmailTemplateByTemplateName(this.emailTemplateName, this.emailTemplateType)
            .subscribe((response: GenericResponseDto<EmailTemplate>) => {
                if (response.status !== ResponseStatus.Success) {
                    return;
                }
                if (!response.data) {
                    return;
                }
                this.emailTemplateForm.patchValue(response.data);
                if (response.data.emailVariables) {
                    this.emailVariables = response.data.emailVariables;
                }
            });
    }

    redirectTo(route: string) {
        const interval = setInterval(() => {
            this.router.navigate([route]);
            clearInterval(interval);
        }, 5000);
    }

    getCurrentWorkflow() {
        this.workflowService.getWorkflowByAccountNumber(this.accountNumber)
            .subscribe((response: GenericResponseDto<Workflow>) => {
                if (response.status === ResponseStatus.Success) {
                    if (response.data) {
                        this.actionType = response.data.actionType ?? "";
                        this.currentWorkflowStatus.emit(response.data.actionType ?? "");

                        if (response.data.feedback) {
                            if (this.feedbackObject.length === 0) {
                                this.feedbackObject = response.data.feedback;
                                this.initializeComments();
                            }
                        }
                    }
                }
            });
    }

    backTo() {
        this.router.navigate(
            [this.userRole === UserRole.Internal ? "/dashboard" : "/vendor/dashboard"]
        );
    }
}
